import React from 'react'

const Resume = ({ data }) => {
	if (data) {
		var skillmessage = data.skillmessage
		var education = data.education.map(function (education) {
			return (
				<div key={education.school}>
					<h3>{education.school}</h3>
					<p className='info'>
						{education.degree} <span>&bull;</span>
						<em className='date'>{education.graduated}</em>
					</p>
					<p>{education.description}</p>
				</div>
			)
		})
		var work = data.work.map(function (work) {
			return (
				<div key={work.company}>
					<h3>{work.company}</h3>
					<p className='info'>
						{work.title}
						<span>&bull;</span> <em className='date'>{work.years}</em>
					</p>
					<p>{work.description}</p>
				</div>
			)
		})

		var skill = data.skill.map(function (skill) {
			return (
				<div key={skill.company}>
					<h3>{skill.company}</h3>

					<p className='info'>
						<span>&bull;</span>
						{skill.title}
					</p>
					<p className='info'>
						<span>&bull;</span>
						{skill.title2}
					</p>
					<p className='info'>
						<span>&bull;</span>
						{skill.title3}
					</p>
				</div>
			)
		})

		var skill2 = data.skill2.map(function (skill2) {
			return (
				<div key={skill2.company}>
					<h3>{skill2.company}</h3>

					<p className='info'>
						<span>&bull;</span>
						{skill2.title}
					</p>
					<p className='info'>
						<span>&bull;</span>
						{skill2.title2}
					</p>
					<p className='info'>
						<span>&bull;</span>
						{skill2.title3}
					</p>
				</div>
			)
		})
	}

	return (
		<section id='resume'>
			<div className='row education'>
				<div className='three columns header-col'>
					<h1>
						<span>Education</span>
					</h1>
				</div>

				<div className='nine columns main-col'>
					<div className='row item'>
						<div className='twelve columns'>{education}</div>
					</div>
				</div>
			</div>

			<div className='row work'>
				<div className='three columns header-col'>
					<h1>
						<span>Work</span>
					</h1>
				</div>

				<div className='nine columns main-col'>{work}</div>
			</div>

			<div className='row skill'>
				<div className='three columns header-col'>
					<h1>
						<span>Skills</span>
					</h1>
				</div>

				<div className='nine columns main-col'>{skill}</div>
				<div className='nine columns main-col'>{skill2}</div>
			</div>
		</section>
	)
}

export default Resume
